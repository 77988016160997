import Button from '@components/AcoButtons/Button';
import IconButton from '@components/AcoButtons/IconButton';
import ACOTooltip from '@components/ACOTooltip/ACOTooltip';
import { Avatar } from '@components/Avatar/Avatar';
import MentionsTextInput from '@components/common/MentionsTextInput';
import FilesUploader from '@components/FilesUploader/FilesUploader';
import { RegisterGeolocationInput } from '@components/layout/Register/GeolocationInput/RegisterGeolocationInput';
import SelectedMenu from '@components/SelectedMenu/SelectedMenu';
import styles from '@components/ticket/TicketFormStyles';
import { KeyboardArrowUp } from '@mui/icons-material';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import WhereToVoteOutlinedIcon from '@mui/icons-material/WhereToVoteOutlined';
import { Box, Typography, MenuItem, Menu, TextField } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { UploadFilesStore, UploadFilesStoreContext } from '@providers/hooks/fileuploads/UploadFilesStoreContext';
import { OnDesktop, OnMobile, useIsMobile } from '@providers/Responsive.provider';
import { TicketErrors } from '@providers/ticket/NewTicketContext';
import { useSession } from '@providers/user/SessionContext';
import { Location, UserRegisterLocationData, BusinessAdministrator } from '@service/model';
import axios from 'axios';
import React, { ReactNode, useState } from 'react';
import useSWR from 'swr';

export interface TextCardProps {
  updateLocation?: (newLocation: Location | UserRegisterLocationData) => void,
  updateText?: (value: string) => void,
  updateMentions?: (mention) => void,
  text?: string,
  mentions?: string,
  privacy?: number,
  updatePostPrivacy?: (value: number) => void,
  publisher?: number,
  updatePostPublisher?: (value: number) => void,
  location?: Location | UserRegisterLocationData,
  removeLocation?: () => void,
  errors?: TicketErrors,
  uploadFilesStore?: UploadFilesStore,
  isPost?: boolean;
  sharedObjectNode?: ReactNode;
}

const TextCard: React.FC<{ TextCardProps: TextCardProps }> = (props) => {
  const classes = styles();

  const MAX_FILE_SIZE_POST = 5242880, // Bytes
    MAX_FILE_SIZE_TICKET = 10485760, // Bytes
    MAX_FILES = 3;

  const { user } = useSession();
  const isMobile = useIsMobile();
  const { snackbars } = useCustomSnackbar();

  const [showUploadForm, setShowUploadForm] = useState(false);
  const [showLocationInput, setShowLocationInput] = useState(false);

  const [publisher, setPublisher] = useState<{ name: string, img: string }>({
    name: user?.fullName,
    img: user?.avatarUrl,
  });

  const [stateLocation, setStateLocation] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.TextCardProps.updateText(event.target.value);
    setTextLength(event.target.value.length);

  };

  const handleChangePublisher = (index) => {
    const publisherId = index != undefined ? businessAdmin?.businessAdmin[index].empresa_id : undefined;
    props.TextCardProps.updatePostPublisher(publisherId);
    const publisherName = index != undefined ? businessAdmin?.businessAdmin[index].nombre_completo : user?.fullName;
    const publisherImg = index != undefined ? businessAdmin?.businessAdmin[index].img_perfil : user?.avatarUrl;

    setPublisher({ ...publisher, name: publisherName, img: publisherImg });
  };

  const handleAddAttachment = () => {
    setShowUploadForm(prevState => !prevState);
  };

  const handleOpenLocation = () => {
    setShowLocationInput(prevState => !prevState);
  };

  const handleUpdateLocation = () => {

    if (handleCheckStateLocation() || stateLocation) {
      props.TextCardProps.updateLocation(props.TextCardProps.location);
      setStateLocation(false);
    }

    handleOpenLocation();
  };

  const handleCheckStateLocation = () => {
    if (props.TextCardProps.location != null) {
      return true;
    } else if (props.TextCardProps.location == null) {
      setStateLocation(true);

      return false;
    }
  };

  const handlePrivacyIcon = {
    0: <PublicIcon fontSize='small' />,
    1: <PeopleIcon fontSize='small' />,
    2: <LockIcon fontSize='small' />,
  };

  const urlAdmin = user ? `/api/user/${user.id}/businessAdmin` : null;

  const fetcherAdmin = (urlAdmin: string) => axios.get<{
    businessAdmin: BusinessAdministrator[],
    error?: string
  }>(urlAdmin).then((res) => res.data);

  const { data: businessAdmin } = useSWR(urlAdmin, fetcherAdmin);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (businessAdmin.error) {
      snackbars.showError(businessAdmin.error)
    }
    else {
      setAnchorEl(event.currentTarget)
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const url = user ? `/api/location/${user?.id}` : null;
  const fetcher = (url: string) => axios.get<{ location: UserRegisterLocationData }>(url).then((res) => res.data);
  const { data } = useSWR(url, fetcher);

  if (props.TextCardProps.location === undefined) {
    data && data.location && props.TextCardProps.updateLocation(data.location);
  }

  const [textLength, setTextLength] = React.useState(0);
  const [viewLength, setViewLength] = React.useState(false);

  const sharedObjectNode = props.TextCardProps.sharedObjectNode || (<></>);

  return (
    <Box display={isMobile ? 'unset' : 'flex'}>
      <Box display='flex' flexDirection='row' alignItems='flex-start'>
        {props.TextCardProps.isPost ?
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Avatar src={publisher.img} alt={publisher.name} size={{ desktop: 48, mobile: 40 }} />
            {businessAdmin?.businessAdmin?.length > 0 &&
              <><ACOTooltip title={'Cambiar de empresa'} arrow>
                <Button onClick={handleClick} className={classes.buttonIcon}>
                  {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </Button>
              </ACOTooltip>
                <Menu
                  anchorEl={anchorEl}
                  id='account-menu'
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'auto',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      borderRadius: '8px',
                      mt: 0,
                      '& .MuiAvatar-root': {
                        width: 48,
                        height: 48,
                        ml: 0,
                        mr: 1,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >
                  <MenuItem onClick={() => handleChangePublisher(undefined)}>
                    <Avatar src={user?.avatarUrl} alt={user?.fullName}
                            size={{ desktop: 48, mobile: 40 }} />
                    {user?.fullName}
                  </MenuItem>
                  {open && businessAdmin?.businessAdmin.map((e: BusinessAdministrator, index: number) => (
                    <MenuItem key={index} onClick={() => handleChangePublisher(index)}>
                      <Avatar src={e.img_perfil} alt={e.nombre_completo}
                              size={{ desktop: 48, mobile: 40 }} />
                      {e.nombre_completo}
                    </MenuItem>
                  ))}
                </Menu></>}</Box>
          :
          <Avatar src={user?.avatarUrl} alt={user?.fullName} size={{ desktop: 48, mobile: 40 }} />
        }
        {props.TextCardProps.isPost && isMobile && user &&
          <Box margin='0 0 20px 10px'>
            <Typography variant='subtitle2'>{publisher.name}</Typography>
            {publisher.name == user?.fullName && <Typography variant='body1'>@{user?.nickname}</Typography>}
          </Box>
        }
      </Box>
      <Box className={classes.ticketTextBox}>
        <Box className={classes.ticketTextInputComponents}>

          {props.TextCardProps.isPost ?
            <MentionsTextInput TextCardProps={{
              updateText: props.TextCardProps.updateText,
              updateMentions: props.TextCardProps.updateMentions,
              isPost: props.TextCardProps.isPost,
              text: props.TextCardProps.text,
            }} /> :

            <TextField
              placeholder={'¿Qué querés consultar?  (Mínimo 50 caracteres)'}
              multiline
              rows={5}
              variant='filled'
              size={isMobile ? 'small' : 'medium'}
              className={classes.textFieldInput}
              value={props.TextCardProps.text}
              onChange={handleChangeTextField}
              onFocus={() => setViewLength(true)}
              onBlur={() => setViewLength(false)}
            />
          }

          <Box className={classes.buttonsArea}>
            <Box flexGrow='1'>
              <OnDesktop>
                <Button
                  className={classes.button}
                  onClick={handleAddAttachment}
                  disableRipple
                  startIcon={<AttachFileIcon fontSize='small' />}>
                  Adjuntar archivos
                </Button>

                <Button
                  className={
                    props.TextCardProps.errors.location ? `${classes.button} error` : `${classes.button} `
                  }
                  onClick={handleOpenLocation}
                  disableRipple
                  startIcon={<AddLocationAltOutlinedIcon fontSize='small' />}>
                  {!showLocationInput && props.TextCardProps.location
                    ? props.TextCardProps.location.address
                    : 'Agregar ubicación'}
                </Button>
              </OnDesktop>
              <OnMobile>
                <IconButton size='large' onClick={handleAddAttachment} className={classes.button} sx={{ minWidth: 0 }}>
                  <AttachFileIcon fontSize='small' />
                </IconButton>
                <IconButton size='large' onClick={handleOpenLocation} className={classes.button}>
                  {props.TextCardProps.location ? (
                    <>
                      <WhereToVoteOutlinedIcon fontSize='small' color='primary' />
                      <Typography
                        variant='body1'
                        className={classes.textLocation}
                        sx={{maxWidth: props.TextCardProps.isPost ? '29vw' : '50vw'}}>
                        {props.TextCardProps.location.address}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <AddLocationAltOutlinedIcon fontSize='small' />
                      <Typography variant='body1' sx={{ marginLeft: '4px', textTransform: 'none', lineHeight: '16px' }}>
                        Agregar ubicación
                      </Typography>
                    </>
                  )}
                </IconButton>
              </OnMobile>
            </Box>
            {!!props.TextCardProps.isPost && (
              <Box className={classes.boxSelectPrivacy}>
                {handlePrivacyIcon[props.TextCardProps.privacy]}
                <SelectedMenu
                  label={''}
                  classes={classes.select}
                  options={[
                    { text: 'Todos', key: '0' },
                    { text: 'Seguidores', key: '1' },
                    { text: 'Solo yo', key: '2' },
                  ]}
                  onSelect={props.TextCardProps.updatePostPrivacy}
                />
              </Box>
            )}

            {!props.TextCardProps.isPost && viewLength && (
              <Box style={{ margin: '10px' }}>
                <span style={{ margin: '0px' }}>{textLength}/800</span>
              </Box>
            )}

          </Box>

          {showLocationInput && (
            <Box className={classes.boxLocation}>
              <Box className='boxGeneral'>
                <Box className='inputLocation'>
                  <RegisterGeolocationInput
                    onSelect={(location) => props.TextCardProps.updateLocation(location)}
                    onErase={() => props.TextCardProps.updateLocation(props.TextCardProps.location)}
                  />
                </Box>

                <Box className='boxButtons'>
                  <Button color='secondary' onClick={() => handleOpenLocation()}>
                    Cancelar
                  </Button>
                  <Button variant='contained' color='primary' onClick={() => handleUpdateLocation()}>
                    Guardar
                  </Button>
                </Box>
              </Box>

              {props.TextCardProps.location && (
                <Box className={classes.chipLocation}>
                  <LocationOnOutlinedIcon />
                  <Typography>{props.TextCardProps.location.address}</Typography>
                  <IconButton
                    size='small'
                    style={{ minWidth: '28px', padding: '0px', color: 'white' }}
                    disableRipple
                    onClick={() => props.TextCardProps.removeLocation()}
                    color='primary'>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>

        {sharedObjectNode}

        {showUploadForm && (
          <Box>
            <UploadFilesStoreContext.Provider value={props.TextCardProps.uploadFilesStore}>
              <FilesUploader maxNumFiles={MAX_FILES} />
            </UploadFilesStoreContext.Provider>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TextCard;
