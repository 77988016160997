import { Advertising } from '@components/Advertising/Advertising';
import { TicketCard, TicketCardProps } from '@components/cards/card';
import Comments from '@components/Comments/Comments';
import ObjectView from '@components/layout/ObjectView/ObjectView';
import { GoogleForm } from '@components/modal/GoogleForm/GoogleForm';
import { LoginModal } from '@components/modal/Login/LoginModal';
import AcoSEO from '@components/SEO/AcoSEO';
import { ErrorState } from '@components/States/ErrorState';
import SimilarTicketsLayout from '@components/ticket/similarTickets/SimilarTicketsLayout';
import CommentProvider from '@providers/comment/CommentsContext';
import { UsefulStoreContextProvider } from '@providers/hooks/useful/UsefulStoreContext';
import { useSession } from '@providers/user/SessionContext';
import { createSSRContinuationContext } from '@server/shared/request-continuation';
import { serverSidePropsBuilder } from '@server/shared/server-side-props';
import { Ticket } from '@service/model';
import ticketsService from '@service/tickets/tickets.service';
import { convertObjectToCard } from '@shared/converters/objects-to-card.converter';
import { GA } from '@shared/ga/ga';
import { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';


interface TicketPageProperties {
  ticket: Ticket;
  ticketCardProps: TicketCardProps;
}

export function showRegisterModal(md5Hash) {
  return parseInt(md5Hash, 32) % 100 >= 50;
}

const TicketPage: NextPage<TicketPageProperties> = ({ ticket, ticketCardProps }) => {

  const [formModal, setFormModal] = useState<{
    open: boolean,
    title: string,
    url: string,
    primaryBanner: { src: string, alt: string }[],
    banners: { src: string, alt: string }[]
  }>({ open: false, title: undefined, url: undefined, primaryBanner: undefined, banners: undefined });

  const [cookies, setCookie] = useCookies();
  const { user: user } = useSession();
  const [loginOpen, setLoginOpen] = useState(false);

  const statsLateralProps = {
    useful: {
      count: ticket.stats.useful,
      hasUseful: ticket.userStats.hasUseful,
    },
    comments: {
      count: ticket.stats.comments,
    },
    object: {
      type: ticket.objectType,
      id: ticket.id,
    },
    share: {
      text: `Respuesta de ${ticketCardProps.expert.name} a: ${ticketCardProps.question}`,
      redirectUrl: `/${ticket.objectType.toLowerCase()}s/${ticket.id}`,
    },
  };

  const breadcrumbsProps = {
    breadcrumbs: [
      { href: '/', text: 'Expertos' },
      { href: ticketCardProps.expert.url, text: `${ticket.expert.user.name} ${ticket.expert.user.lastName}` },
      { text: `Consulta #${ticket.id}` },
    ],
  };

    useEffect(() => {
      const thematicCropProductionCheck = ticket?.thematics[1]?.name == 'Cereales y oleaginosas' && ticket?.thematics[3]?.name == 'Manejo y control de malezas';

      if (thematicCropProductionCheck && (!cookies['Agroconsultas::Survey::AcuronBorja'] || user?.isAdmin)) {
        setFormModal({
          open: true,
          title: 'Manual Recomendaciones para el manejo de malezas difíciles en el cultivo de maíz<br> Completa este breve formulario y accedé al link de descarga',
          url: 'https://docs.google.com/forms/d/e/1FAIpQLSedLP8PcI_A0iXZyAhpSNKXlqOJQN6QJ5tqVUiIeQLt_CmPfQ/viewform?embedded=true',
          primaryBanner: [{ src: '/img/GoogleForm/Syngenta.png', alt: 'Logo Syngenta' }],
          banners: [{ src: '/img/GoogleForm/AcuronUno.png', alt: 'Logo AcuronUno' },
            { src: '/img/GoogleForm/Borja.png', alt: 'Logo Borja' }],
        });

        if (!user?.isAdmin) {
          const date = new Date();
          date.setDate(date.getDate() + 10);
          setCookie('Agroconsultas::Survey::AcuronBorja', { expires: date });
          GA.event({ action: 'open_survey', category: 'modal', label: 'apertura de encuesta', value: 1 });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

  if (ticketCardProps.state == 'Cancelado' &&
    (!user?.isAdmin ||
      user?.id !== ticketCardProps.client.id ||
      user?.id !== ticketCardProps.expert.id)) {
    return <ErrorState/>
  }

  return (
    <>
      <AcoSEO object={ticket} />
      <UsefulStoreContextProvider>
        <CommentProvider>
          <ObjectView statsLateralProps={statsLateralProps} breadcrumbsProps={breadcrumbsProps}>
            <Advertising />
            <TicketCard {...ticketCardProps} noRedirect isPageView />
            {formModal.open && <GoogleForm
              modalProps={{ open: formModal.open, onClose: () => setFormModal({ ...formModal, open: false }) }}
              form={{ title: formModal.title, url: formModal.url, primaryBanner: formModal.primaryBanner, banners: formModal.banners }}
            />}

            {loginOpen && <LoginModal modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
                                      loginModalProps={{ notRedirect: true, isAutomaticModal: true, noClose: true }} />}


            {ticket.state === 'Aprobado' &&
              <Comments reference={{ referenceType: ticket.objectType, referenceId: ticket.id }}
                        count={ticket.stats.comments} />}

            <SimilarTicketsLayout id={ticket.id} state={ticket.state} content={ticket.question} />
          </ObjectView>
        </CommentProvider>
      </UsefulStoreContextProvider>
    </>
  );
};

export default TicketPage;

export const getServerSideProps = serverSidePropsBuilder<TicketPageProperties>()
  .use(createSSRContinuationContext())
  .handle(async ({ params, query }) => {
    const { tid } = params;

    const ticket = await ticketsService.getTicket(tid as string, true, query.sourceObjectType as string, query.sourceObjectId as string);
    const ticketCardProps = convertObjectToCard(ticket).props as TicketCardProps;

    return { ticket, ticketCardProps };
  });
