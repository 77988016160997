import { InfoEvent } from '@components/OnlineEvent/PaymentEvents/InfoEvent';
import styles from '@components/OnlineEvent/PaymentEvents/PaymentEvent.styles';
import { MercadoPagoForm } from '@components/Payment/MercadoPagoForm';
import PayPalPaymentForm from '@components/Payment/PayPal';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { sendGTMEvent } from '@next/third-parties/google';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { useIsMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { Coupons, OnlineEvent, ResendEmail } from '@service/model';
import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

export enum UserGender {
  MASCULINO = 1,
  FEMENINO = 2,
  NOBINARIO = 3,
}

export const PaymentEvent: React.FC<{ event: OnlineEvent }> = ({ event }) => {
  const classes = styles();
  const { user } = useSession();
  const { snackbars } = useCustomSnackbar();
  const isMobile = useIsMobile();
  const router = useRouter();

  const [isClickButton, setIsClickButton] = useState<boolean>(false);
  const [showFormPayment, setShowFormPayment] = useState<boolean>(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [coupon, setCoupon] = useState<Coupons>(undefined);

  useEffect(() => {
    axios.get(`/api/trainings/coupons?eventId=${event.id}`)
      .then((res) => {
        setCoupon(res.data);
      });

    sendGTMEvent({
      event: 'checkout_start', value: { id: event.id, name: event.title }, category: 'checkout_event',
      label: 'Inicio pago capacitacion',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialOptions = {
    clientId: process.env.NEXT_PUBLIC_AWGZR_RBHDtsFblQMGJxjhmUBnqjctMef5Wb3mkB8f9nELdwFxLz3jd2KdeUjPdvtZH4wstMZVIKa7gZ,
    'enable-funding': 'paylater,venmo',
    'data-sdk-integration-source': 'integrationbuilder_sc',
    'locale': 'es_AR',
  };


  const handlePaymentWithCoupon = async () => {
    setLoadingPayment(true);

    axios
      .post<{ success: boolean, error?: string }>('/api/trainings/coupons', {
        eventId: event.id,
        couponKey: coupon.cupon,
        couponId: coupon.cupon_id,
      })
      .then((res) => {
        if (res.data.success) {
          router.push(`/events/${event.id}`);

          sendGTMEvent({
            event: 'purchase', value: { id: event.id, name: event.title },
            category: 'purchase_event', label: 'Pagó capacitacion con cupón',
          });
        } else {
          snackbars.showError('Error al canjear el cupón');
          setLoadingPayment(false);
        }
      })
      .catch((err) => {
        console.warn('Cannot swap coupon for event', err);
      });
  };

  const handleClickSendMail = () => {
    sendEmail();
  };

  const handleClickFeedback = (e) => {
    e.stopPropagation();

    router.push({
      pathname: '/feedback',
      query: { reason: 'Pago de capacitacion' },
    });

  };

  const sendEmail = async () => {
    axios
      .get<ResendEmail>('/api/onlineEvents/send-email', { params: { email: user.email, eventId: event.id } })
      .then((res) => {
        if (res.data.success) {
          snackbars.showSuccess('Email enviado');
          setIsClickButton(true);
        } else {
          snackbars.showError('No pudimos enviar el email');
        }
      })
      .catch((err) => {
        console.warn('Cannot check email for user', err);
        snackbars.showError('No pudimos enviar el email');
      });
  };


  return (
    <Box className={classes.cardEvent}>

      <InfoEvent event={event} shoyMoneyArg={showFormPayment} hasCoupon={coupon?.success} />

      {coupon?.success && <LoadingButton
        size='medium'
        onClick={handlePaymentWithCoupon}
        loading={loadingPayment}
        endIcon={<CardGiftcardOutlinedIcon />}
        loadingPosition='end'
        variant='contained'
        style={{ marginBottom: '10px' }}
      >
        {loadingPayment ? 'Pagando' : 'Utilizar cupón'}
      </LoadingButton>
      }

      {(user?.country?.includes('Argent') || showFormPayment ?
          <MercadoPagoForm onlineEvent={event} /> :
          (<Box width={isMobile ? '100%' : '580px'}>
              <PayPalScriptProvider options={initialOptions}>
                <PayPalPaymentForm onlineEvent={event} />
              </PayPalScriptProvider>
            </Box>
          )
      )}

      <Typography>Realizando el pago aceptas los <a href='#'><b>términos y
        condiciones</b></a></Typography>
      <Typography>Ante cualquier inconveniente con el pago y/o suscripción a esta capacitación <b
        onClick={handleClickFeedback} style={{ cursor: 'pointer' }}>hace click acá</b></Typography>
    </Box>
  );

};
